import MainContainer from "./MainContainer"

const SiteBody = () => {
  return(
    <main className="container">
      <MainContainer />
    </main>
  )
}

export default SiteBody;